.vertical-tabs-group {
  flex-direction: row !important;
  background: #fff;

  // Header Column
  .mat-tab-header {
    padding: 24px;
    border-right: 1px solid rgba($neutralColor, 0.102);
    border-bottom: none !important;
    box-sizing: border-box;

    .mat-tab-labels {
      flex-direction: column;
      gap: 8px;
    }

    .mat-tab-label {
      @include text-subtitle2;

      justify-content: flex-start;
      padding: 0 16px;
      height: 42px;
      min-width: 240px;
      border-radius: 4px;
      color: $neutralColor;
      opacity: 1;

      mat-icon {
        color: $neutralDarkGreyColor;
      }

      &.mat-tab-label-active {
        background: $greyLightHoverColor;
        color: $neutralColor;

        mat-icon {
          color: $neutralColor;
        }
      }

      &:hover {
        background: $tableBorderColor;
        color: $neutralColor;

        mat-icon {
          color: $neutralColor;
        }
      }

      .mat-tab-label-content {
        gap: 16px;
      }
    }

    .mat-ink-bar {
      display: none;
    }
  }

  // Body Column
  .mat-tab-body-wrapper {
    flex: 1 1 auto;
    padding: 24px;
    background: rgba($tableBorderColor, 0.3);
    box-sizing: border-box;

    .mat-tab-body.mat-tab-body-active {
      overflow: unset;
    }

    .mat-tab-body-content {
      overflow: unset;
    }

    & p, a, li, b {
      color: $greyDarkPressedColor;
    }
  }
}

@mixin responsive_md() {
  .vertical-tabs-group {
    flex-direction: column !important;

    .mat-tab-header {
      border-bottom: 1px solid rgba($neutralColor, 0.102) !important;
      border-right: none !important;

      .mat-tab-labels {
        flex-direction: row !important;
        flex-wrap: wrap;
      }

      .mat-tab-label {
        min-width: 200px;
      }
    }
  }
}

@mixin responsive_sm() {
  .vertical-tabs-group {
    .mat-tab-header {
      padding: 16px;

      .mat-tab-label {
        min-width: 100px;
      }
    }

    .mat-tab-body-wrapper {
      padding: 16px;
    }
  }
}

@container page-container (max-width: 767px) {
  @include responsive_md();
}

@container page-container (max-width: 600px) {
  @include responsive_sm();
}

@media screen and (max-width: 767px) {
  @include responsive_md();
}

@media screen and (max-width: 600px) {
  @include responsive_sm();
}
