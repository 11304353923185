mat-calendar {
  td.mat-calendar-body-cell-container {
    &:after {
      width: auto;
      height: auto;
    }
  }
}

mat-calendar.week-picker {
  mat-month-view table.mat-calendar-table {
    .mat-calendar-body tr:hover {
      .mat-calendar-body-label {
        ~ td.mat-calendar-body-cell-container {
          &:last-child:before {
            border-bottom-right-radius: 999px;
            border-top-right-radius: 999px;
          }
        }
      }

      td.mat-calendar-body-cell-container {
        &:nth-child(1):before {
          border-bottom-left-radius: 999px;
          border-top-left-radius: 999px;
          left: 5%;
        }

        &:nth-child(7):before {
          border-bottom-right-radius: 999px;
          border-top-right-radius: 999px;
          right: 5%;
        }

        &:before {
          content: "";
          position: absolute;
          top: 5%;
          left: 0%;
          right: 0%;
          bottom: 5%;
          background: #27c26c;
          border-radius: 0;
          pointer-events: none;
          opacity: 0.1;
        }

        &:has(.custom-selected) {
          &:before {
            background: #118846;
            opacity: 0.3;
          }
        }
      }
    }
  }
}

mat-calendar.days-picker,
mat-calendar.week-picker,
mat-calendar.month-picker,
mat-calendar.day-picker {
  mat-month-view table.mat-calendar-table {
    user-select: none;

    .mat-calendar-body td.mat-calendar-body-cell-container {
      .mat-calendar-body-cell-content.mat-calendar-body-today {
        border-color: transparent;
      }

      &:has(.custom-today) {
        .mat-calendar-body-cell-content {
          border-color: rgba(0, 0, 0, 0.38);
        }

        &:has(.custom-selected) {
          .mat-calendar-body-cell-content {
            box-shadow: inset 0 0 0 1px white;
          }
        }
      }

      &:has(.custom-selected) {
        &:after {
          content: "";
          position: absolute;
          top: 5%;
          left: 0%;
          right: 0%;
          bottom: 5%;
          background: #27c26c;
          border-radius: 0;
          pointer-events: none;
        }

        .mat-calendar-body-cell-content {
          color: white;
          z-index: 1;
        }

        &:has(.custom-start) {
          &:after {
            border-bottom-left-radius: 999px;
            border-top-left-radius: 999px;
            left: 5%;
          }
        }

        &:has(.custom-end) {
          &:after {
            border-bottom-right-radius: 999px;
            border-top-right-radius: 999px;
            right: 5%;
          }
        }
      }
    }
  }

  // Custom picker hover selection
  &.days-picker-end mat-month-view table.mat-calendar-table .mat-calendar-body:hover {
    td.mat-calendar-body-cell-container:has(.custom-possible-selection) {
      &:not(:has(.custom-start)):after {
        content: "";
        position: absolute;
        top: 5%;
        left: 0%;
        right: 0%;
        bottom: 5%;
        background: rgba(#27c26c, 0.2);
        border-radius: 0;
        pointer-events: none;
      }

      &:has(.custom-start) {
        &:after {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          right: 0;
        }
      }

      &:hover {
        &:after {
          border-bottom-right-radius: 999px;
          border-top-right-radius: 999px;
          right: 5%;
        }
      }
    }

    td.mat-calendar-body-cell-container {
      &:hover ~ td.mat-calendar-body-cell-container:has(.custom-possible-selection) {
        &:not(:has(.custom-start)):after {
          background: none;
        }

        &:after {
          border-bottom-right-radius: 999px;
          border-top-right-radius: 999px;
          right: 5%;
        }
      }
    }

    tr:hover ~ tr {
      td.mat-calendar-body-cell-container:has(.custom-possible-selection) {
        &:not(:has(.custom-start)):after {
          background: none;
        }

        &:after {
          border-bottom-right-radius: 999px;
          border-top-right-radius: 999px;
          right: 5%;
        }
      }
    }
  }
}

// TODO: Exploring the possibilities. Not ready for production yet
// Show previous and next month days
mat-calendar.show-prev-next-month {
  .mat-calendar-content {
    overflow: hidden;
  }

  mat-month-view table.mat-calendar-table .mat-calendar-body {
    tr:last-child {
      &:after {
        content: "\a0 1 2 3 4 5 6 7";
        overflow: visible;
        display: table-cell;
        width: 0;
        height: 40px;
        white-space: nowrap;
        max-width: 0;
        vertical-align: middle;
        letter-spacing: 15px;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
