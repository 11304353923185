@font-face {
  font-family: "Articulat CF";
  src: url("../assets/fonts/400-ArticulatCF-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Articulat CF";
  src: url("../assets/fonts/500-ArticulatCF-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Articulat CF";
  src: url("../assets/fonts/600-ArticulatCF-DemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Articulat CF";
  src: url("../assets/fonts/700-ArticulatCF-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Articulat CF";
  src: url("../assets/fonts/900-ArticulatCF-Heavy.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@mixin text-heading3() {
  font-weight: 400;
  font-size: 48px;
  line-height: 57px;
  margin: 0;
}

@mixin text-heading4() {
  font-weight: 400;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  margin: 0;
}

@mixin text-heading5() {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
}

@mixin text-heading6() {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
}

@mixin text-body1() {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.44px;
}

@mixin text-body2() {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

@mixin text-subtitle1() {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
}

@mixin text-subtitle2() {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 0;
}

@mixin text-caption() {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

@mixin text-button() {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
}

@mixin text-button2() {
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.64px;
  text-transform: uppercase;
}

@mixin text-login-form-links() {
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 700;
  font-size: 16px;
}

@mixin text-footer-links() {
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  font-weight: 500;
}

.typography-h3 {
  @include text-heading3();
}

.typography-h4 {
  @include text-heading4();

  &.bold {
    font-weight: 700;
  }
}

.typography-h5 {
  @include text-heading5();
  &.bold {
    font-weight: 500;
  }
}

.typography-h6 {
  @include text-heading6();
}

.typography-body1 {
  @include text-body1();
  &.bold {
    font-weight: 700;
  }
}

.typography-body2 {
  @include text-body2();

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }
}

.typography-subtitle1 {
  @include text-subtitle1();

  &.bold {
    font-weight: 700;
  }
}

.typography-subtitle2 {
  @include text-subtitle2();
}

.typography-caption {
  @include text-caption();
  &.bold {
    font-weight: 700;
  }
}

.typography-button {
  @include text-button();
}

.typography-button2 {
  @include text-button2();
}

.no-margin {
  margin: 0 !important;
}
