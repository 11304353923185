.no-anim {
  transition: none !important;
}

mat-slide-toggle.no-anim {
  transition: none !important;

  .mat-slide-toggle-bar {
    transition: none !important;
  }

  .mat-slide-toggle-thumb-container {
    transition: none !important;
  }

  .mat-slide-toggle-thumb {
    transition: none !important;
  }
}

mat-checkbox.no-anim {
  .mat-checkbox-frame {
    transition: none !important;
  }

  .mat-checkbox-background {
    transition: none !important;
  }
}
