@import "./variables";

span.skeleton {
  display: inline-block;
}

mat-checkbox {
  .skeleton {
    display: inline-block;
  }
}

.skeleton {
  color: $skeletonColor;
  border-color: $skeletonColor;
  background: $skeletonColor;
  border-style: solid;
  box-sizing: border-box;
  width: 104px;
  height: 16px;
  animation: alternate skeletonPulse 1.5s ease-in-out 0.5s infinite;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  font-size: 0;
  line-height: 0;
  border-width: 0;
  opacity: 0.4;
  vertical-align: middle;

  &.auto {
    width: auto;
    height: auto;
  }

  &.circle {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &.icon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &.donut {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: transparent;
    border-width: 4px;
    flex-shrink: 0;
  }

  &.bar {
    height: 4px;
  }

  &.badge {
    width: 64px;
    border-radius: 8px;
  }

  &.pie {
    width: 152px;
    height: 152px;
    border-radius: 50%;
    background: transparent;
    border-width: 24px;
    flex-shrink: 0;
  }

  &.mr4 {
    margin-right: 4px;
  }

  &.mr8 {
    margin-right: 8px;
  }

  &.mr16 {
    margin-right: 16px;
  }

  &.mb4 {
    margin-bottom: 4px;
  }

  &.mb8 {
    margin-bottom: 8px;
  }

  &.static {
    animation: none;
  }

  &.wave {
    &:after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      position: absolute;
      animation: skeletonWave 1.6s linear 0.5s infinite;
      transform: translateX(-100%);
      background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    }
  }
}

.skeleton-text {
  color: transparent !important;

  &:before {
    @extend .skeleton;
    content: "\a0";
    line-height: inherit;
    font-size: inherit;
    height: auto;
    width: 100%;
    display: inline-block;
    padding-right: 30%;
    box-sizing: border-box;
    background-clip: content-box;
    vertical-align: bottom;
  }
}

.mat-table.skeleton-table .mat-cell,
.mat-row.skeleton-table .mat-cell,
.mat-header-row.skeleton-table .mat-header-cell,
.mat-cell.skeleton-table,
.mat-header-cell.skeleton-table {
  &:not(.unskeleton) {
    background: $skeletonColor;
    background-clip: content-box;
    animation: alternate skeletonPulse 1.5s ease-in-out 0.5s infinite;
    pointer-events: none;
    opacity: 0.4;

    padding: 16px 8px !important;
    color: transparent !important;

    > * {
      opacity: 0 !important;
    }
  }
}

@keyframes skeletonPulse {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes skeletonWave {
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}
