// https://github.com/angular/components/issues/11677
.mat-menu-panel {
  overflow: initial !important;

  .mat-menu-content {
    overflow: auto;
    overflow-y: overlay;
    height: 100%;
    box-sizing: border-box;
  }
}
